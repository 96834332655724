import React from 'react';
import { Container } from 'reactstrap';
import { Clock, Year } from './Clock'

const Footer = () => {
    return(
        <footer className="bg-custom-1 text-center py-3">
            <Container>
                <Clock/>
                <p className="mb-0 mt-0 pt-0 pr-2 ltr-spacing"><small>Copyright &copy; <Year /> Janina's Web Designs<span className="hide-show">. </span><br className="show-hide" /><span className="hide-show">All rights reserved.</span></small></p>            
            </Container>
        </footer>
    )
}

export default Footer;