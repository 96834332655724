import React, { useState, useEffect } from "react";
import { Route } from 'react-router-dom';
import ModalCreatePortfolio from '../individual/ModalCreatePortfolio';
import { Button, Form, Modal } from "react-bootstrap";
import { withRouter } from "react-router";
import { useHistory } from 'react-router-dom';

const PortfolioPieces = (props) => {
    
    const id = props.match.params.id;
    // For Put Modal
    const [one, oneShow] = useState(false)
    const handleUpdateCloseModal = () => oneShow(false);
    const handleUpdateShowModal = (event, updatePortfolioItem) => {
        event.preventDefault();
        oneShow(true);
        setUpdatePortfolioItem(updatePortfolioItem);
    };
    // For Map, and Delete Button
    const [portfolioItems, setPortfolioItems] = useState([]);
    const [updatePortfolioItem, setUpdatePortfolioItem] = useState({  image: "", title: "", description: "", link: "" });
    const history = useHistory();

    useEffect(() => {
        async function fetchData() {
        const res = await fetch(`${process.env.REACT_APP_BACKENDBASEURL}/api/portfolio`);
        res.json().then((res) => setPortfolioItems(res))
        }
        fetchData();
    }, [id]);      

    const handleDeleteSubmit = (event, id) => {
        event.preventDefault();

        fetch(`${process.env.REACT_APP_BACKENDBASEURL}/api/portfolio/${id}`, {       
            method: "delete",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        }).then((response) => response.json());
        history.go(0)
    };

    const handleUpdateChange = (event) => {
        event.persist();
        setUpdatePortfolioItem((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
    };
    
    const handleUpdateSubmit = (event, id) => {
        event.preventDefault();
        fetch(`${process.env.REACT_APP_BACKENDBASEURL}/api/portfolio/${id}`, {
            method: "put",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(updatePortfolioItem),
            }).then((response) => response.json());
            history.go(0);
        };

    return(
        <>
        <Route exact path="/login/listings" render={() => 
            <div className="row px-3 pb-3 d-flex justify-content-between">
                <h1 className="h2">PORTFOLIO</h1>
                <ModalCreatePortfolio />
            </div>
        }/>
        <div className="row px-2">
            {portfolioItems.map((portfolio) => (
                <div className="col-lg-4 col-md-12 px-2 pt-0 mb-3">
                    <div className="card text-center font-col-custom-2 p-3 bg-custom-2 height-custom-2" key={portfolio.id}>
                        <a href={portfolio.link} target="_blank" rel="noopener noreferrer"><img className="card-img-top border-custom-1 img-responsive height-custom-1" src={portfolio.image} alt={portfolio.title} title={portfolio.title} /></a>
                        <div className="card-body">
                            <h5 className="card-title">{portfolio.title}</h5>
                            <p className="card-text">{portfolio.description}</p>
                        </div>
                        <div className="card-footer bg-custom-10 p-0">
                            <a href={portfolio.link} className="btn btn-dark d-inline-block btn-custom-black" target="_blank" rel="noopener noreferrer">View</a>
                        </div>
                    </div>
                    <Route exact path="/login/listings" render={() => 
                        <div className="d-flex justify-content-center bg-custom-7 bor-rad-1 py-3">
                            <Button
                                className="btn-sm ml-2 text-uppercase" 
                                color="primary"
                                onClick={(e) => {
                                    handleUpdateShowModal(e, portfolio);
                                }}
                            >
                                Update
                            </Button>
                            <Button 
                                className="btn-sm ml-2 text-uppercase" 
                                color="primary"
                                onClick={(e) => {
                                    handleDeleteSubmit(e, portfolio.id);
                                    }}
                            >
                                Delete
                            </Button>
                        </div> 
                    }/>
                </div>
            ))}  
            
            <Modal
                show={one}
                onHide={handleUpdateCloseModal}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="modal-title w-100 text-center">Edit Portfolio Piece</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={(e) => handleUpdateSubmit(e, updatePortfolioItem.id)}>
                        <Form.Group controlId="formBasicImage">
                            <Form.Label>Image</Form.Label>
                            <Form.Control
                                type="text"
                                name="image"
                                value={updatePortfolioItem.image}
                                onChange={handleUpdateChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formBasicTitle">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                name="title"
                                value={updatePortfolioItem.title}
                                onChange={handleUpdateChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formBasicDescription">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                type="text"
                                name="description"
                                value={updatePortfolioItem.description}
                                onChange={handleUpdateChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formBasicImage">
                            <Form.Label>Link</Form.Label>
                            <Form.Control
                                type="text"
                                name="link"
                                value={updatePortfolioItem.link}
                                onChange={handleUpdateChange}
                            />
                        </Form.Group>
                        <Button className="in-modal-btn mt-4" variant="primary" type="submit" block>
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleUpdateCloseModal}>
                        Close Modal
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
        </>
    );
};

export default withRouter(PortfolioPieces);