import React, { useState } from 'react';
import { Form, FormGroup, Col, Input, Label, Button, CardBody, Card, CardText, Row } from 'reactstrap';
import registrationFull from './../../img/registration-full.jpg';
import registrationMobile from './../../img/registration-mobile.jpg';
import { Link, useHistory } from "react-router-dom";
import Navigation from './../shared/Navigation';
import parseJwt from '../../helpers/authHelper';
import { motion } from 'framer-motion';
import dividerLineFull from './../../img/divider-full.jpg';
import dividerLineMobile from './../../img/divider-mobile.jpg';

const NewUser = () => {

    const pageVariants = {
        initial: {
            opacity: 0,
            x: "-100vw"
        },
        in: {
            opacity: 1,
            x: 0
        },
        out: {
            opacity: 0,
            x: "100vw"
        }
    };

    const pageTransition = {
        duration: 1, 
        type: "spring",
        stiffness: 30,
        ease: "easeIn"
    };

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const token = sessionStorage.getItem('token');
    const user = parseJwt(token).email;

    let history = useHistory()
    const logout = event => {
        event.preventDefault()
        sessionStorage.removeItem('token')
        history.push("/login")
    }

    const formSubmit = async event => {
        event.preventDefault()
        const response = await fetch(`${process.env.REACT_APP_BACKENDBASEURL}/api/users`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({email, password})
        })
        const payload = await response.json()
        if (response.status >= 400) {
            alert(`Oops! Error: ${payload.message} for fields: ${payload.invalid.join(",")}`)
        } else {
            alert(`Congrats! A new user was created.`)
        }
    }

    return (
        <>
            <header className="jumbotron mar-bot-spacing pb-md-4 pb-sm-3 pb-2 mt-md-4 pt-3 rounded-0 header-spacing">
                <Navigation />
                <div className="container bg-transparent mar-under-nav text-center bg-custom-2 mb-0 pt-sm-3 pt-2 pb-1 rounded-0">
                <h1 className="pb-sm-2 pb-0 mb-sm-1 mb-0 pt-sm-0 pt-0">Janina's <br className="line-height display-inline-block show-hide"/>Web Designs<br /></h1>
                    <img src={dividerLineFull} alt="" className="line_full mb-3 header-line img-fluid" height={3} width={420} />
                    <img src={dividerLineMobile} alt="" className="line_mobile mb-1 header-line img-fluid" height={6} width={420} />
                    <h2 className="pt-md-2 pt-1 font-col-custom-1 animate__animated animate__zoomIn">Create New User</h2>
                </div>
            </header>
            <motion.main 
                className="container text-white pad-x-spacing mar-bot-spacing"
                initial="initial" 
                animate="in" 
                exit="out" 
                variants={pageVariants} 
                transition={pageTransition}
                style={{ overflowX: "hidden" }}
            >

                {/* Section: Image */}
                <section className="mar-bot-spacing bor-rad-1 mb-3">
                    {/* Image by Gerd Altmann from Pixabay, https://pixabay.com/photos/registration-password-try-again-4519979/, registration-4519979_1920.jpg, modified */}
                    <img id="registration" className="d-block w-100 bor-rad-1 img-fluid image_full" src={registrationFull} width={1080} height={450} alt="Word Registration with Pointing Finger" title="New User" />
                    <img id="registration" className="d-block w-100 bor-rad-1 img-fluid image_mobile" src={registrationMobile} width={1080} height={450} alt="Word Registration with Pointing Finger" title="New User" />
                </section>

                {/* Section: Contact Form */}
                <section className="container bg-custom-5 px-3 py-1 bor-1 bor-rad-3">
                    <Form className="mt-4 text-white" onSubmit={formSubmit}>
                        <Row>
                            <h3 className="h4 ml-3 text-white">Options for user: <span className="user">{user}</span></h3>
                        </Row>
                        <Card className="text-white bg-custom-3 my-3 py-4 text-center">
                            <CardBody>
                                <CardText className="text-white m-0">Use the form below to create a new user. This action will allow someone else access to confidential data.</CardText>
                            </CardBody>
                        </Card>
                        <Row form className="pb-4 pt-3">
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="emailEntry">New User Email</Label>
                                    <Input type="email" name="email" id="emailEntry" placeholder="Enter valid email"  required value={email} onChange={e => setEmail(e.target.value) }/>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="passwordEntry">New User Password</Label>
                                    <Input type="password" name="password" id="passwordEntry" placeholder="Enter valid password" onChange={e => setPassword(e.target.value)}/>
                                </FormGroup>
                            </Col>       
                        </Row>
                        <Button color="primary" className="mr-3 mb-3">Submit</Button>
                        <Link to="/login/listings"><Button className="mr-3 mb-3" color="primary">Return to Listings</Button></Link>
                        <Button className="mr-3 mb-3" onClick={logout} color="primary">Logout</Button>
                    </Form>
                </section>
            </motion.main>
        </>
    )
}

export default NewUser;
