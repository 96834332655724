import React from 'react';

const optionsDate = { month: 'short', day: 'numeric', year: 'numeric' }
const optionsTime =  { hour: '2-digit', minute: '2-digit', second: '2-digit' }
const optionsYear = { year: 'numeric' }

class Clock extends React.Component { 
    /** @param {Record<string, any>} props */
    constructor(props) {
        super(props);
        this.state = {date: new Date()};
    }

    componentDidMount() {
        this.timerID = setInterval(
            () => this.tick(),
            1000
        );
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    tick() {
        this.setState({
            date: new Date()
        });
    }

    render() {
        return (
            <div>
                <p className="mb-1 pb-0 pr-2 font-size-1 text-uppercase">
                    {this.state.date.toLocaleDateString(undefined, optionsDate)}
                    &nbsp; 
                    <span className="clock" role="img" aria-label="clock emoji">&#9200;</span>
                    &nbsp; 
                    <span className="ltr-spacing-2">{this.state.date.toLocaleTimeString(undefined, optionsTime)}</span>
                </p>
            </div>
        );
    }
}

class Year extends React.Component { 
    /** @param {Record<string, any>} props */
    constructor(props) {
        super(props);
        this.state = {date: new Date()};
    }

    componentDidMount() {
        this.timerID = setInterval(
            () => this.tick(),
            1000
        );
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    tick() {
        this.setState({
            date: new Date()
        });
    }

    render() {
        return (
                <span>{this.state.date.toLocaleDateString(undefined, optionsYear)}</span>
        );
    }
}

export { Clock, Year };