import React from 'react';
import { Row, Col} from 'reactstrap';
import Navigation from './../shared/Navigation';
import { motion } from 'framer-motion';
import 'animate.css';
import dividerLineFull from './../../img/divider-full.jpg';
import dividerLineMobile from './../../img/divider-mobile.jpg';
import videoCodesFull from './../../video/Codes-23355-full.mp4';
import videoCodesMobile from './../../video/Codes-23355-mobile.mp4';

const Home = () => {

    const pageVariants = {
        initial: {
            opacity: 0,
            x: "-100vw"
        },
        in: {
            opacity: 1,
            x: 0
        },
        out: {
            opacity: 0,
            x: "100vw"
        }
    };

    const pageTransition = {
        duration: 1, 
        type: "spring",
        stiffness: 30,
        ease: "easeIn"
    };
    
    return(
        <>
            <header className="jumbotron mar-bot-spacing pb-md-4 pb-sm-3 pb-2 mt-md-4 pt-3 rounded-0 header-spacing">
                <Navigation />
                <div className="container bg-transparent mar-under-nav text-center bg-custom-2 mb-0 pt-sm-3 pt-2 pb-1 rounded-0">
                <h1 className="pb-sm-2 pb-0 mb-sm-1 mb-0 pt-sm-0 pt-0">Janina's <br className="line-height display-inline-block show-hide"/>Web Designs<br /></h1>
                    <img src={dividerLineFull} alt="" className="line_full mb-3 header-line img-fluid" height={3} width={420} />
                    <img src={dividerLineMobile} alt="" className="line_mobile mb-1 header-line img-fluid" height={5} width={420} />
                    <h2 className="pt-md-2 pt-1 font-col-custom-1 animate__animated animate__zoomIn">Web Developer</h2>
                </div>
            </header>
            <motion.main 
                className="container pad-x-spacing"
                initial="initial" 
                animate="in" 
                exit="out" 
                variants={pageVariants} 
                transition={pageTransition}
                style={{ overflowX: "hidden" }}
            >

                {/* Section: Video */}
                <section className="mar-bot-spacing bor-rad-1">
                    {/* Video by Joseph Redfield Nino from Pixabay, https://pixabay.com/videos/codes-internet-computer-code-23355/, "Codes - 23355.mp4", modified */}
                    <video
                        className="img-fluid video_full"
                        playsInline
                        loop
                        muted
                        autoPlay
                        alt="All the devices"
                        src={videoCodesFull}
                        controls={false}
                        preload='auto'
                        type='video/mp4'
                    />
                    <video
                        className="img-fluid video_mobile"
                        playsInline
                        loop
                        muted
                        autoPlay
                        alt="All the devices"
                        src={videoCodesMobile}
                        controls={false}
                        preload='auto'
                        type='video/mp4'
                    />
                </section>

                {/* Section: Summary */}
                <section>
                    <article>
                        <Row className="mar-bot-spacing summary">
                            <Col lg="6" md="12" className="pad-right-spacing pad-bottom-spacing">
                                <div className="mar-right-space home border border-dark bor-rad-1 p-md-5 p-sm-4 p-3 bg-custom-2">
                                    <h3 className="pb-lg-4 pb-md-3 pb-1 text-center">Full-Stack <br /> Web Development</h3>
                                    <p className="pb-0 mb-0">
                                        As a full-stack web developer, I am trained in client-side and server-side web development and cloud deployment. This includes HTML5, CSS3, React, Sass, Emmet, Bootstrap, jQuery, JavaScript &#40;ES6+&#41;, MySQL, Node.js, Express, PHP, Google Cloud Platform, Amazon Web Services, Docker, GitHub, GitLab, AR, WordPress, Dreamweaver, Photoshop, Illustrator.
                                    </p>
                                </div>
                            </Col>
                            <Col lg="6" md="12" className="pad-left-spacing pad-left">
                                <div className="mar-left-space mar-left-spacing home border border-dark bor-rad-1 p-md-5 p-sm-4 p-3 bg-custom-2">
                                    <h3 className="pb-lg-4 pb-md-3 pb-1 text-center">Responsive &amp;<br />Accessible Sites</h3>
                                    <p className="pb-0 mb-0">
                                        With the growing variety of devices available &#40;cell phones, tablets, laptops, desktops, smart TVs, appliances, etc.&#41; and technology advancing, the need for responsive web applications becomes vital. It is also important that the Internet is accessible for everyone and the <a href="https://www.w3.org/WAI/standards-guidelines/wcag/" target="_blank" rel="noopener noreferrer"> Web Content Accessibility Guidelines &#40;WCAG 2&#41;</a> provides the required international standard. 
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </article>
                </section>

                {/* Section: Quotes */}
                <section className="bor-rad-1 mar-top-spacing">
                    <article>
                        <Row className="home-footer">
                            {/* Original Source: https://www.youtube.com/watch?v=UF8uR6Z6KLc */}
                            <Col id="a" lg="6" md="12" className="pad-right-spacing pad-bottom-spacing margin-bottom-special">
                                <div className="mar-right-space bor-rad-1 bor-custom-5 border-mobile">
                                    <div className="quote">
                                        <blockquote className="bg-custom-3 mb-0 text-dark p-4 bor-rad-1 font-size-quotes"><span className="d-inline-block border-quote-left pl-md-4 pl-3 pr-lg-3 pr-0 ltr-spacing-3"><q>The only way to do great work is to love what you do.</q><br /> ~ <a href="https://www.youtube.com/watch?v=Tuw8hxrFBH8" target="_blank" rel="noopener noreferrer">Steve Jobs</a></span></blockquote> 
                                    </div>
                                </div>
                            </Col>
                        
                            <Col id="b" lg="6" md="12" className="pad-left-spacing pad-left mb-3">
                                <div className="mar-left-spacing bor-rad-1 px-1 bg-custom-3 border-mobile">
                                    <div className="quote quote-2-parent pb-md-0 pb-2">
                                        {/* https://en.wikiquote.org/wiki/Steve_Jobs */}
                                        <blockquote className="bg-custom-3 mb-0 text-dark p-md-4 pt-0 pb-4 bor-rad-1 font-size-quotes quote-2-parent"><span className="d-inline-block border-quote-left pl-3 ltr-spacing-3 quote-2"><q>The first step is to establish that something is possible.</q><br /> ~ <a href="https://twitter.com/elonmusknewsorg/status/960928584163577856?lang=en" target="_blank" rel="noopener noreferrer">Elon Musk</a></span></blockquote>
                                    </div>
                                </div>
                            </Col>  
                        </Row> 
                    </article>
                </section>
                
            </motion.main>
        </>
    )
}

export default Home;