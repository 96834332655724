import React from 'react';
import { BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import './App.css';
import Footer from './components/shared/Footer';
import Home from './components/pages/Home';
import Resume from './components/pages/Resume';
import Portfolio from './components/pages/Portfolio';
import Contact from './components/pages/Contact';
import Login from './components/pages/Login';
import Listings from './components/pages/Listings';
import NewUser from './components/pages/NewUser';
import PrivateRoute from './components/shared/PrivateRoute';
import Navigation from './components/shared/Navigation';
import ScrollToPageTop from './components/shared/ScrollToPageTop';

function App() {
    return (
        <BrowserRouter>
            <Navigation />
            <AnimatePresence exitBeforeEnter>
            <ScrollToPageTop />
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/resume" component={Resume} />
                    <Route exact path="/portfolio" component={Portfolio} />
                    <Route exact path="/contact" component={Contact} />
                    <Route exact path="/login" component={Login} />
                    <PrivateRoute path="/login/registration">
                        <NewUser />
                    </PrivateRoute>
                    <PrivateRoute exact path="/login/listings">
                        <Listings />
                    </PrivateRoute>
                    <Redirect from="*" to="/" />
                </Switch> 
            </AnimatePresence>
            <Footer /> 
        </BrowserRouter>
    )
}

export default App;