import React from 'react';
import TabPanelYork from '../individual/TabPanelYork';
import TabPanelSeneca from '../individual/TabPanelSeneca';
import Navigation from './../shared/Navigation';
import { motion } from 'framer-motion';
import ReadExperience from '../shared/ReadResumeExperience';
import ReadEducation from '../shared/ReadResumeEducation';
import dividerLineFull from './../../img/divider-full.jpg';
import dividerLineMobile from './../../img/divider-mobile.jpg';
import videoCoffeeFull from './../../video/Coffee-38057-full.mp4';
import videoCoffeeMobile from './../../video/Coffee-38057-mobile.mp4';

const Resume = () => {

    const pageVariants = {
        initial: {
            opacity: 0,
            x: "-100vw"
        },
        in: {
            opacity: 1,
            x: 0
        },
        out: {
            opacity: 0,
            x: "100vw"
        }
    };

    const pageTransition = {
        duration: 1, 
        type: "spring",
        stiffness: 30,
        ease: "easeIn"
    };

    return (
        <>
            <header className="jumbotron mar-bot-spacing pb-md-4 pb-sm-3 pb-2 mt-md-4 pt-3 rounded-0 header-spacing">
                <Navigation />
                <div className="container bg-transparent mar-under-nav text-center bg-custom-2 mb-0 pt-sm-3 pt-2 pb-1 rounded-0">
                <h1 className="pb-sm-2 pb-0 mb-sm-1 mb-0 pt-sm-0 pt-0">Janina's <br className="line-height display-inline-block show-hide"/>Web Designs<br /></h1>
                    <img src={dividerLineFull} alt="" className="line_full mb-3 header-line img-fluid" height={3} width={420} />
                    <img src={dividerLineMobile} alt="" className="line_mobile mb-1 header-line img-fluid" height={6} width={420} />
                    <h2 className="pt-md-2 pt-1 font-col-custom-1 animate__animated animate__zoomIn">Resume</h2>
                </div>
            </header>
            <motion.main 
                className="container text-white pad-x-spacing mar-bot-spacing"
                initial="initial" 
                animate="in" 
                exit="out" 
                variants={pageVariants} 
                transition={pageTransition}
                style={{ overflowX: "hidden" }}
            >

                {/* Section: Video */}
                <section className="mar-bot-spacing bor-rad-1">
                    {/* Video by Engin Akyurt from Pixabay, https://pixabay.com/videos/coffee-laptop-work-house-school-38057/, "Coffee - 38057.mp4" modified */}
                    <video
                        className="img-fluid video_full"
                        playsInline
                        loop
                        muted
                        autoPlay
                        alt="All the devices"
                        src={videoCoffeeFull}
                        controls={false}
                        preload='auto'
                        type='video/mp4'
                    />
                    <video
                        className="img-fluid video_mobile"
                        playsInline
                        loop
                        muted
                        autoPlay
                        alt="All the devices"
                        src={videoCoffeeMobile}
                        controls={false}
                        preload='auto'
                        type='video/mp4'
                    />
                </section>

                {/* Section: Resume Content */}
                <section>
                    <article>
                        <div className="row pad-x-spacing">
                            <div className="resume pb-md-3 px-md-5 pt-md-5 pb-0 px-3 pt-3 col-12 mb-3 bor-rad-1 bg-custom-2 border border-dark">
                                <ReadExperience />
                            </div>
                            <div className="resume pt-md-4 px-md-5 pb-md-5 pt-2 px-3 pb-3 col-12 bor-rad-1 bg-custom-2 border border-dark">
                                <ReadEducation />
                            
                                {/* Section: Tab Panel York */}
                                <section>
                                    <div className="row pb-md-4 pb-3 mb-md-3 mb-1 pl-md-0 pl-3">
                                        {/* <div className="tab offset-sm-2 col-sm-10 offset-1 col-11"> */}
                                        <div className="tab offset-md-2 col-md-10 col-12">

                                            <TabPanelYork />

                                        </div>
                                    </div>
                                </section>

                                {/* Section Seneca Experience Hardcoded*/}
                                <section>
                                {/* <section className="border border-danger border-5"> */}
                                {/* <img src={divider} height={5} className="img-fluid image_full" alt="Page divider" title="Decorative Page Divider" /> */}
                                    <div className="d-flex justify-content-between">
                                        <div className="d-inline-block pt-2">
                                        </div>
                                    </div>
                                    <div className="pb-md-3">
                                        <div className="row">
                                            <div className="offset-md-0 col-md-2 col-12">
                                                <p className="custom-font">2020</p>
                                            </div>
                                            {/* <div className="offset-sm-0 col-sm-10 offset-1 col-11"> */}
                                            <div className="offset-md-0 col-md-10 col-12 education">
                                                <h4 className="h5 pb-1"><a href="https://web-design-development.senecacollege.ca/" target="_blank" rel="noopener noreferrer">Webmaster Content Site Design Certificate</a></h4>
                                                <h4 className="h5 py-1">Seneca College</h4>    
                                                <p className="pl-md-0 pl-3"><strong>Courses Completed: </strong> Webmaster Content I, Webmaster Content II, Webmaster Content III, Multimedia, Web Programming, Webmaster Project Planning and Development, Emerging Web Technologies, Webmaster Project, Database Driven Websites</p>
                                            </div>
                                            <div className="col-12 d-flex justify-content-end">
                                            </div> 
                                        </div>
                                    </div>
                                </section>

                                {/* Section: Tab Panel Seneca */}
                                <section>
                                    <div className="row mb-lg-0 mb-md-0 mb-md-0 pl-md-0 pl-3">
                                        {/* <div className="tab offset-sm-2 col-sm-10 offset-1 col-11"> */}
                                        <div className="tab offset-md-2 col-md-10 col-12">
                                            <TabPanelSeneca />

                                        </div>
                                    </div>
                                </section>

                            </div>
                        </div>

                    </article>
                </section>

            </motion.main>
        </>
    )
}

export default Resume;