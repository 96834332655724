import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import {useHistory, useLocation} from 'react-router-dom';

const PortfolioForm = () => {
    const[newPortfolio, setNewPortfolio] = useState({ image: "", title: "", description: "", link: ""})
    const history = useHistory();
    const location = useLocation();

    const handleChange = (event) => {
        event.persist();
        setNewPortfolio((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
    };

    const handleSubmit = (event) => {
        fetch(`${process.env.REACT_APP_BACKENDBASEURL}/api/portfolio`, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

            body: JSON.stringify(newPortfolio),
            }).then((response) => response.json());

            sessionStorage.setItem('token')

            let { from } = location.state || { from: { pathname: "/login/listings" } }
            history.replace(from)

    };

    return (
        <Form onSubmit={handleSubmit}>

            <Form.Group controlId="formBasicImage">
                <Form.Label>Project Image</Form.Label>
                <Form.Control
                    type="img"
                    placeholder="Enter Image URL"
                    name="image"
                    value={newPortfolio.image}
                    onChange={handleChange}
                />
            </Form.Group>

            <Form.Group controlId="formBasicTitle">
                <Form.Label>Project Title</Form.Label>
                <Form.Control
                    type="title"
                    placeholder="Enter title"
                    name="title"
                    value={newPortfolio.title}
                    onChange={handleChange}
                />
            </Form.Group>

            <Form.Group controlId="formBasicDescription">
                <Form.Label>Project Description</Form.Label>
                <Form.Control
                    type="description"
                    placeholder="Enter description"
                    name="description"
                    value={newPortfolio.description}
                    onChange={handleChange}
                />
            </Form.Group>

            <Form.Group controlId="formBasicLink">
                <Form.Label>Project Link</Form.Label>
                <Form.Control
                    type="link"
                    placeholder="Enter link"
                    name="link"
                    value={newPortfolio.link}
                    onChange={handleChange}
                />
            </Form.Group>

            <Button className="in-modal-btn" variant="primary" type="submit" block>
                Submit
            </Button>

        </Form>

    );
};

const ModalCreatePortfolio = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onPortfolioFormSubmit = (e) => {
        e.preventDefault();
        handleClose();
    };

    return (
        <>
            <div>
                <Button variant="primary" onClick={handleShow} className="ml-2 btn text-uppercase">
                    Create
                </Button>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="modal-title w-100 text-center">New Portfolio Project</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PortfolioForm onSubmit={onPortfolioFormSubmit} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>  
    );
};

export default ModalCreatePortfolio;