import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';

const TabPanelYork = (props) => {
    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }

    return (
        <div>
            {/* <p><strong><a href="https://continue.yorku.ca/programs/certificate-in-full-stack-web-development/">Program Details:</a></strong></p> */}
            <Nav tabs fill className="justify-content-between york gallery">
                <NavItem className="bg-custom-6 bor-rad-tab mr-1 mt-1">
                    <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => { toggle('1'); }}
                        activeStyle={{color: 'red', backgroundColor: 'pink'}}
                    >
                        CSFS1000
                    </NavLink>
                </NavItem>
                <NavItem className="bg-custom-6 bor-rad-tab mr-1 mt-1">
                    <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => { toggle('2'); }}
                    >
                        CSFS1020
                    </NavLink>
                </NavItem>
                <NavItem className="bg-custom-6 bor-rad-tab mr-1 mt-1">
                    <NavLink
                        className={classnames({ active: activeTab === '3' })}
                        onClick={() => { toggle('3'); }}
                    >
                        CSFS1010
                    </NavLink>
                </NavItem>
                <NavItem className="bg-custom-6 bor-rad-tab mr-1 mt-1">
                    <NavLink
                        className={classnames({ active: activeTab === '4' })}
                        onClick={() => { toggle('4'); }}
                    >
                        CSFS1030
                    </NavLink>
                </NavItem>
                <NavItem className="bg-custom-6 bor-rad-tab mr-1 mt-1">
                    <NavLink
                        className={classnames({ active: activeTab === '5' })}
                        onClick={() => { toggle('5'); }}
                    >
                        CSFS1040
                    </NavLink>
                </NavItem>
                <NavItem className="bg-custom-6 bor-rad-tab mr-1 mt-1">
                    <NavLink
                        className={classnames({ active: activeTab === '6' })}
                        onClick={() => { toggle('6'); }}
                    >
                        CSFS1050
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent className="content" activeTab={activeTab}>
                <TabPane tabId="1">
                    <Row>
                        <Col sm="12">
                            <h5 class="pt-4 h6">CSFS 1000 - Fundamentals of Full Stack Web Development</h5>
                            <ul>
                                <li>HTML5</li>
                                <li>CSS3</li>
                                <li>JavaScript</li>
                                <li>GitHub</li>
                            </ul>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="2">
                    <Row>
                        <Col sm="12">
                            <h5 class="pt-4 h6">CSFS 1020 - Server Side Programming and Tools</h5>
                            <ul>
                                <li>JavaScript (ES6+)</li>
                                <li>Node.js</li>
                                <li>Asynchronous Programming</li>
                                <li>HTTP</li>
                                <li>RESTful APIs</li>
                                <li>ExpressJS</li>
                                <li>Authentication and Security</li>
                                <li>Filesystem Operations</li>
                                <li>Automated testing</li>
                                <li>Build tooling</li>
                            </ul>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="3">
                    <Row>
                        <Col sm="12">
                            <h5 class="pt-4 h6">CSFS 1010 - Web UI Concepts and Frameworks</h5>
                            <ul>
                                <li>HTML</li>
                                <li>CSS</li>
                                <li>Responsive UIs</li>
                                <li>Bootstrap</li>
                                <li>JavaScript (ES6+)</li>
                                <li>JSX</li>
                                <li>React</li>
                                <li>DOM API</li>
                                <li>Fetch API</li>
                                <li>Automated testing</li>
                                <li>Build tooling</li>
                            </ul>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="4">
                    <Row>
                        <Col sm="12">
                            <h5 class="pt-4 h6">CSFS 1030 - Database Design and Principles</h5>
                            <ul>
                                <li>MySQL</li>
                                <li>WorkBench</li>
                                <li>MongoDB</li>
                                <li>Draw.io</li>
                            </ul>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="5">
                    <Row>
                        <Col sm="12">
                            <h5 class="pt-4 h6">CSFS 1040 - DevOps and Deployment</h5>
                            <ul>
                                <li>Google Cloud Platform (GCP)</li>
                                <li>Amazon Web Services (AWS)</li>
                                <li>Docker</li>
                                <li>Node.js</li>
                                <li>MySQL</li>
                                <li>React</li>
                                <li>Git</li>
                                <li>GitLab</li>
                            </ul>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="6">
                    <Row>
                        <Col sm="12">
                            <h5 class="pt-4 h6">CSFS 1050 - Capstone</h5>
                            <ul>
                                <li>React</li>
                                <li>JavaScript</li>
                                <li>Ajax</li>
                                <li>Node/Express</li>
                                <li>Restful Routes</li>
                                <li>MySQL</li>
                                <li>Google Cloud</li>
                                <li>Trello</li>
                                <li>Modeling tools</li>
                            </ul>
                        </Col>
                    </Row>
                </TabPane>
            </TabContent>
        </div>
    );
}

export default TabPanelYork;