import React, { useEffect, useState } from 'react';
import { Row, Table } from 'reactstrap';
import parseJwt from '../../helpers/authHelper';
import { Button } from "react-bootstrap";
import { withRouter } from "react-router";
import { useHistory } from 'react-router-dom';

const ListingsEntries = (props) => {

    let id = props.match.params.id;

    const token = sessionStorage.getItem('token')
    const user = parseJwt(token).email
    const [listing, setListing] = useState([])
    const history = useHistory();

    useEffect(() => {
        async function fetchData() {
        const res = await fetch(`${process.env.REACT_APP_BACKENDBASEURL}/api/contact_form/entries`);
        res.json().then((res) => setListing(res))
        }
        fetchData();
    }, [id]);   

    const handleDeleteSubmit = (event, id) => {
        event.preventDefault();

        fetch(`${process.env.REACT_APP_BACKENDBASEURL}/api/contact_form/entries/${id}`, {       
            method: "delete",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        }).then((response) => response.json());
        history.go(0)

    };

    return (
        <>
            <main>

                {/* Section: Private Listings */}
                <section class="bg-custom-5 mar-top-spacing px-1 pt-1 pb-2 bor-rad-3 bor-1">
                    <Row>
                        <h3 className="h4 ml-3 text-white">Contact Form Entries for user: <span className="user">{user}</span></h3>
                    </Row>
                    <Table responsive className="text-white border">
                        <thead>
                            <tr className="bg-custom-2 text-dark text-center">
                                <th>ID</th>
                                <th>NAME</th>
                                <th>PHONE &#35;</th>
                                <th>EMAIL</th>
                                <th>CONTENT</th>
                                <th className="border-right">DELETE</th>
                            </tr>
                        </thead>
                        <tbody className="border-left border-right border-bottom">
                            {listing.length === 0 &&
                                <tr><td colSpan="12" className="text-center"><i>No listings found</i></td></tr>
                            }
                            {listing.length > 0 &&
                                listing.map(entry => 
                                <tr>
                                    <td className="border-right align-middle">{entry.id}</td>
                                    <td className="border-right align-middle">{entry.name}</td>
                                    <td className="border-right align-middle">{entry.phoneNumber}</td>
                                    <td className="border-right align-middle">{entry.email}</td>
                                    <td className="border-right align-middle">{entry.content}</td>
                                    <td className="border-right align-middle">
                                        <Button 
                                        className="btn-sm ml-2 text-uppercase" 
                                        color="primary"
                                        onClick={(e) => {
                                            handleDeleteSubmit(e, entry.id);
                                            }}
                                    >
                                        Delete
                                        </Button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>  
                </section>

            </main>
        </>
    )
}

export default withRouter(ListingsEntries);