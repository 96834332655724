import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Route} from 'react-router-dom';
import ModalCreateEducation from '../individual/ModalCreateEducation';
import { withRouter } from "react-router";
import { useHistory } from 'react-router-dom';

const ReadEducation = (props) => {

    const [one, oneShow] = useState(false)
    const handleUpdateCloseModal = () => oneShow(false);
    const handleUpdateShowModal = (event, updateEducationItem) => {
        event.preventDefault();
        oneShow(true);
        setUpdateEducationItem(updateEducationItem);
    };

    const [educationExps, setEducationExps] = useState([]);
    let id = props.match.params.id;
    const history = useHistory();
    const [updateEducationItem, setUpdateEducationItem] = useState({ years: "", title: "", school: "", description: "" });

    useEffect(() => {
        async function fetchData() {
        const res = await fetch(`${process.env.REACT_APP_BACKENDBASEURL}/api/education`);
        res.json().then((res) => setEducationExps(res))
        }
        fetchData();
    }, [id]);    
    
    const handleSubmitDelete = (event, id) => {
        event.preventDefault();
        fetch(`${process.env.REACT_APP_BACKENDBASEURL}/api/education/${id}`, {  
            method: "delete",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        }).then((response) => response.json());
        history.go(0)      
    };

    const handleUpdateChange = (event) => {
        event.persist();
        setUpdateEducationItem((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
    };

    const handleUpdateSubmit = (event, id) => {
        event.preventDefault();
        fetch(`${process.env.REACT_APP_BACKENDBASEURL}/api/education/${id}`, {
            method: "put",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(updateEducationItem),
            }).then((response) => response.json());
            history.go(0);
        };

    return(
        <section className="education">
            <div className="d-flex justify-content-between">
                <h3 className="pb-md-4 pb-md-3 pb-2 pt-md-2 mt-3 d-inline-block text-center">Certifications</h3>

                <Route exact path="/login/listings" render={() => 
                    <div className="d-inline-block pt-2">

                        <ModalCreateEducation />
                        
                    </div>
                }/>

            </div>
            <Route exact path="/login/listings" render={() => 
                <hr className="custom-hr mt-0 pt-0"/>
            }/>       
                {educationExps.map((education) => (
                    <div className="pb-md-3" key={education.id}>
                        <div className="row">
                            <div className="offset-md-0 col-md-2 col-12">
                                <p className="pb-0 custom-font">{education.years}</p>
                            </div>
                            <div className="offset-md-0 col-md-10 col-12 education">
                            {/* <div className="offset-sm-0 col-sm-10 col-12"> */}
                                <h4 className="h5 pb-md-1"><a href="https://continue.yorku.ca/programs/certificate-in-full-stack-web-development/" target="_blank" rel="noreferrer">{education.title}</a></h4>
                                <h4 className="h5 py-1">{education.school}</h4>
                                <p className="pl-md-0 pl-3"><strong>Courses Completed: </strong>{education.description}</p>
                            </div>
                            <Route exact path="/login/listings" render={() => 
                                <div className="col-12 d-flex justify-content-end">
                                    <Button
                                        className="btn-md ml-2 text-uppercase" 
                                        color="primary"
                                        onClick={(e) => {
                                            handleUpdateShowModal(e, education);
                                        }}
                                    >
                                        Update
                                    </Button>
                                    <Button 
                                        className="btn-sm ml-2 text-uppercase" 
                                        color="primary"
                                        onClick={(e) => {
                                            handleSubmitDelete(e, education.id);
                                            }}
                                    >Delete</Button>
                                </div> 
                            }/>
                            <Route exact path="/login/listings" render={() => 
                                <hr className="custom-hr mx-3 mb-0"/>
                            }/>
                        </div>
                    </div>
                ))}  

            <Modal
                show={one}
                onHide={handleUpdateCloseModal}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="modal-title w-100 text-center">Edit Education Piece</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={(e) => handleUpdateSubmit(e, updateEducationItem.id)}>
                        <Form.Group controlId="formBasicYears">
                            <Form.Label>Years</Form.Label>
                            <Form.Control
                                type="text"
                                name="years"
                                value={updateEducationItem.years}
                                onChange={handleUpdateChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formBasicTitle">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                name="title"
                                value={updateEducationItem.title}
                                onChange={handleUpdateChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formBasicSchool">
                            <Form.Label>School</Form.Label>
                            <Form.Control
                                type="text"
                                name="school"
                                value={updateEducationItem.school}
                                onChange={handleUpdateChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formBasicDescription">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                type="text"
                                name="description"
                                value={updateEducationItem.description}
                                onChange={handleUpdateChange}
                            />
                        </Form.Group>
                        <Button className="in-modal-btn mt-4" variant="primary" type="submit" block>
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleUpdateCloseModal}>
                        Close Modal
                    </Button>
                </Modal.Footer>
            </Modal>

        </section>
    );
};

export default withRouter(ReadEducation);
