import React, { useState } from 'react';
import { Col, Row, Button, Form, FormGroup, Label, Input, Card, CardBody, CardText } from 'reactstrap';
import { useHistory, useLocation } from 'react-router-dom';
import Navigation from './../shared/Navigation';
import { motion } from 'framer-motion';
import dividerLineFull from './../../img/divider-full.jpg';
import dividerLineMobile from './../../img/divider-mobile.jpg';
import videoKnightFull from './../../video/Knight-31210-full.mp4';
import videoKnightMobile from './../../video/Knight-31210-mobile.mp4';

const Login = () => {

    const pageVariants = {
        initial: {
            opacity: 0,
            x: "-100vw"
        },
        in: {
            opacity: 1,
            x: 0
        },
        out: {
            opacity: 0,
            x: "100vw"
        }
    };

    const pageTransition = {
        duration: 1, 
        type: "spring",
        stiffness: 30,
        ease: "easeIn"
    };

    let history = useHistory();
    let location = useLocation();
    const [email, setEmail] = useState(""); 
    const [password, setPassword] = useState("");
    const [auth, setAuth] = useState(true);

    const loginSubmit = async event => {
        
        event.preventDefault()
        const response = await fetch(`${process.env.REACT_APP_BACKENDBASEURL}/api/auth`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({email, password})
        })  

        const payload = await response.json()
        if (response.status >= 400) {
            setAuth(false)
        } else {
            sessionStorage.setItem('token', payload.token)

            let { from } = location.state || { from: { pathname: "/login/listings" } }
            history.replace(from)
        }
    }

    return (
        <>
            <header className="jumbotron mar-bot-spacing pb-md-4 pb-sm-3 pb-2 mt-md-4 pt-3 rounded-0 header-spacing">
                <Navigation />
                <div className="container bg-transparent mar-under-nav text-center bg-custom-2 mb-0 pt-sm-3 pt-2 pb-1 rounded-0">
                <h1 className="pb-sm-2 pb-0 mb-sm-1 mb-0 pt-sm-0 pt-0">Janina's <br className="line-height display-inline-block show-hide"/>Web Designs<br /></h1>
                    <img src={dividerLineFull} alt="" className="line_full mb-3 header-line img-fluid" height={3} width={420} />
                    <img src={dividerLineMobile} alt="" className="line_mobile mb-1 header-line img-fluid" height={6} width={420} />
                    <h2 className="pt-md-2 pt-1 font-col-custom-1 animate__animated animate__zoomIn">Login</h2>
                </div>
            </header>
            <motion.main 
                className="container pad-x-spacing"
                initial="initial" 
                animate="in" 
                exit="out" 
                variants={pageVariants} 
                transition={pageTransition}
                style={{ overflowX: "hidden" }}
            >

                {/* Section: Video */}
                <section className="mar-bot-spacing bor-rad-1">
                    {/* Video by Julien Tromeur from Pixabay, https://pixabay.com/videos/knight-3d-medieval-armor-man-31210/, "Knight - 31210.mp4", modified */}
                    <video
                        className="img-fluid video_full"
                        playsInline
                        loop
                        muted
                        autoPlay
                        alt="All the devices"
                        src={videoKnightFull}
                        controls={false}
                        preload='auto'
                        type='video/mp4'
                    />
                    <video
                        className="img-fluid video_mobile"
                        playsInline
                        loop
                        muted
                        autoPlay
                        alt="All the devices"
                        src={videoKnightMobile}
                        controls={false}
                        preload='auto'
                        type='video/mp4'
                    />

                </section>

                {/* Section: Login */}
                <section className="bg-custom-12 mar-top-spacing p-3 bor-rad-1 mar-bot-spacing bg-mobile">
                    {!auth && 
                        <Card className="text-white bg-primary my-5 py-4 text-center">
                            <CardBody>
                                <CardText className="text-white m-0">Invalid credentials, please try again</CardText>
                            </CardBody>
                        </Card>
                    }
                    <Card className="text-white bg-custom-9 mb-4 py-4 text-center">
                        <CardBody>
                            <CardText className="text-white m-0">Login for Administrator access is below.</CardText>
                        </CardBody>
                    </Card>
                    <Form className="my-3 pt-1 text-white" onSubmit={loginSubmit}>
                        <Row form>
                            <Col className="offset-lg-2 offset-md-1" lg={8} md={10}>
                                <FormGroup>
                                    <Label for="emailEntry">Email</Label>
                                    <Input type="email" name="email" id="emailEntry" placeholder="Valid email" onChange={e => setEmail(e.target.value)}/>
                                </FormGroup>
                            </Col>
                            <Col className="offset-lg-2 offset-md-1" lg={8} md={10}>
                                <FormGroup>
                                    <Label for="passwordEntry">Password</Label>
                                    <Input type="password" name="password" id="passwordEntry" placeholder="Valid password" onChange={e => setPassword(e.target.value)}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Button className="mt-2 offset-lg-2 offset-md-1" color="primary">Sign in</Button>
                    </Form>
                </section>
            </motion.main>
        </>
    )
}

export default Login;