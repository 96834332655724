import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import {useHistory, useLocation} from 'react-router-dom';

const EducationForm = () => {
    const[newEducation, setNewEducation] = useState({ years: "", title: "", description: ""})
    const history = useHistory();
    const location = useLocation();

    const handleChange = (event) => {
        event.persist();
        setNewEducation((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
    };

    const handleSubmit = (event) => {
        fetch(`${process.env.REACT_APP_BACKENDBASEURL}/api/education`, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

            body: JSON.stringify(newEducation),
            }).then((response) => response.json());

            sessionStorage.setItem('token')

            let { from } = location.state || { from: { pathname: "/login/listings" } }
            history.replace(from)       
    };

    return (
        <Form onSubmit={handleSubmit}>

            <Form.Group controlId="formBasicYears">
                <Form.Label>Program Years</Form.Label>
                <Form.Control
                    type="years"
                    placeholder="Enter years (2021 - Present)"
                    name="years"
                    value={newEducation.years}
                    onChange={handleChange}
                />
            </Form.Group>

            <Form.Group controlId="formBasicTitle">
                <Form.Label>Program Title</Form.Label>
                <Form.Control
                    type="title"
                    placeholder="Enter title (Web Development)"
                    name="title"
                    value={newEducation.title}
                    onChange={handleChange}
                />
            </Form.Group>

            <Form.Group controlId="formBasicSchool">
                <Form.Label>School</Form.Label>
                <Form.Control
                    type="school"
                    placeholder="Enter school (York University)"
                    name="school"
                    value={newEducation.school}
                    onChange={handleChange}
                />
            </Form.Group>

            <Form.Group controlId="formBasicDescription">
                <Form.Label>Program Description</Form.Label>
                <Form.Control
                    type="description"
                    placeholder="Enter description (Courses)"
                    name="description"
                    value={newEducation.description}
                    onChange={handleChange}
                />
            </Form.Group>

            <Button className="in-modal-btn" variant="primary" type="submit" block>
                Submit
            </Button>

        </Form>
    );
};

const ModalCreateEducation = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onEducationFormSubmit = (e) => {
        e.preventDefault();
        handleClose();
    };

    return (
        <>
            <div>
                <Button variant="primary" onClick={handleShow} className="ml-2 btn-sm text-uppercase">
                    Create
                </Button>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="modal-title w-100 text-center">New Education</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EducationForm onSubmit={onEducationFormSubmit} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close Modal
                    </Button>
                </Modal.Footer>
            </Modal>
        </>  
    );
};

export default ModalCreateEducation;
