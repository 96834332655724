import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';
// import { Link } from 'react-router-dom';

const TabPanelSeneca = (props) => {
    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }

    return (
        <div>
            {/* <p><strong><a href="https://web-design-development.senecacollege.ca/">Program Details:</a></strong></p> */}
            <Nav tabs fill justify className="seneca gallery">
                <NavItem className="bg-custom-6 bor-rad-tab mr-1 mt-1"> 
                    <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => { toggle('1'); }}
                        activeStyle={{color: 'red', backgroundColor: 'pink'}}
                    >
                        WWW100
                    </NavLink>
                </NavItem>
                <NavItem className="bg-custom-6 bor-rad-tab mr-1 mt-1">
                    <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => { toggle('2'); }}
                    >
                        WWW102
                    </NavLink>
                </NavItem>
                <NavItem className="bg-custom-6 bor-rad-tab mr-1 mt-1">
                    <NavLink
                        className={classnames({ active: activeTab === '3' })}
                        onClick={() => { toggle('3'); }}
                    >
                        WWW103
                    </NavLink>
                </NavItem>
                <NavItem className="bg-custom-6 bor-rad-tab mr-1 mt-1">
                    <NavLink
                        className={classnames({ active: activeTab === '4' })}
                        onClick={() => { toggle('4'); }}
                    >
                        WWW104
                    </NavLink>
                </NavItem>
                <NavItem className="bg-custom-6 bor-rad-tab mr-1 mt-1">
                    <NavLink
                        className={classnames({ active: activeTab === '5' })}
                        onClick={() => { toggle('5'); }}
                    >
                        WWW105
                    </NavLink>
                </NavItem>
                <NavItem className="bg-custom-6 bor-rad-tab mr-1 mt-1">
                    <NavLink
                        className={classnames({ active: activeTab === '6' })}
                        onClick={() => { toggle('6'); }}
                    >
                        WWW107
                    </NavLink>
                </NavItem>
                <NavItem className="bg-custom-6 bor-rad-tab mr-1 mt-1">
                    <NavLink
                        className={classnames({ active: activeTab === '7' })}
                        onClick={() => { toggle('7'); }}
                    >
                        WWW108
                    </NavLink>
                </NavItem><NavItem className="bg-custom-6 bor-rad-tab mr-1 mt-1">
                    <NavLink
                        className={classnames({ active: activeTab === '8' })}
                        onClick={() => { toggle('8'); }}
                    >
                        WWW109
                    </NavLink>
                </NavItem>
                <NavItem className="bg-custom-6 bor-rad-tab mr-1 mt-1">
                    <NavLink
                        className={classnames({ active: activeTab === '9' })}
                        onClick={() => { toggle('9'); }}
                    >
                        WWW110
                    </NavLink>
                </NavItem>

            </Nav>
            <TabContent className="content" activeTab={activeTab}>
                <TabPane tabId="1">
                    <Row>
                        <Col sm="12">
                            <h5 class="pt-4 h6">WWW100 Webmaster Content&nbsp;I</h5>
                            <ul>
                                <li>HTML5</li>
                                <li>CSS3</li>
                            </ul>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="2">
                    <Row>
                        <Col sm="12">
                            <h5 class="pt-4 h6">WWW102 Webmaster Content II</h5>
                            <ul>
                                <li>Mobile and Responsive Design</li>
                                <li>FTP/FileZilla</li>
                            </ul>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="3">
                    <Row>
                        <Col sm="12">
                            <h5 class="pt-4 h6">WWW103 Webmaster Content III</h5>
                            <ul>
                                <li>Emmet</li>
                                <li>CSS Architecture:</li>
                                <ul>
                                    <li>Bootstrap</li>
                                    <li>SASS</li>
                                    <li>SCSS</li>
                                </ul>
                            </ul>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="4">
                    <Row>
                        <Col sm="12">
                            <h5 class="pt-4 h6">WWW104 Multimedia</h5>
                            <ul>
                                <li>Photoshop</li>
                                <li>Illustrator</li>
                                <li>Dreamweaver</li>
                            </ul>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="5">
                    <Row>
                        <Col sm="12">
                            <h5 class="pt-4 h6">WWW105 Web Programming</h5>
                            <ul>
                                <li>JavaScript</li>
                                <li>jQuery</li>
                            </ul>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="6">
                    <Row>
                        <Col sm="12">
                            <h5 class="pt-4 h6">WWW107 Webmaster Project Planning and Development</h5>
                            <ul>
                                <li>Client Management</li>
                                <li>Accessibility</li>
                            </ul>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="7">
                    <Row>
                        <Col sm="12">
                            <h5 class="pt-4 h6">WWW108 Emerging Web Technologies</h5>
                            <ul>
                                <li>Emerging Tech</li>
                            </ul>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="8">
                    <Row>
                        <Col sm="12">
                            <h5 class="pt-4 h6">WWW109 Webmaster Project</h5>
                            <ul>
                                <li>Client Project</li>
                                <li>HTML/CSS/Bootstrap/PHP</li>
                                <li>GoDaddy</li>
                            </ul>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="9">
                    <Row>
                        <Col sm="12">
                            <h5 class="pt-4 h6">WWW110 Database Driven Websites</h5>
                            <ul>
                                <li>WordPress</li>
                                <li>PHP</li>
                                <li>MAMP</li>
                                <li>XAMPP</li>
                            </ul>
                        </Col>
                    </Row>
                </TabPane>
            </TabContent>
        </div>
    );
}

export default TabPanelSeneca;