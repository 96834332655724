import React, { useState } from 'react';
import { Form, FormGroup, Col, Input, Label, Button, CardBody, Card, CardText } from 'reactstrap';
import Navigation from './../shared/Navigation';
import { motion } from 'framer-motion';
import dividerLineFull from './../../img/divider-full.jpg';
import dividerLineMobile from './../../img/divider-mobile.jpg';
import videoCellFull from './../../video/Woman-38084-full.mp4';
import videoCellMobile from './../../video/Woman-38084-mobile.mp4';

const Contact = () => {

    const pageVariants = {
        initial: {
            opacity: 0,
            x: "-100vw"
        },
        in: {
            opacity: 1,
            x: 0
        },
        out: {
            opacity: 0,
            x: "100vw"
        }
    };

    const pageTransition = {
        duration: 1, 
        type: "spring",
        stiffness: 30,
        ease: "easeIn"
    };

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [content, setContent] = useState("");

    const formSubmit = async event => {
        event.preventDefault()
        const response = await fetch(`${process.env.REACT_APP_BACKENDBASEURL}/api/contact_form/entries`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({name, email, phoneNumber, content})
        })
        const payload = await response.json();
        if (response.status >= 400) {
            alert(`Oops! Error: ${payload.message} for fields: ${payload.invalid.join(",")}`);
        } else {
            alert(`Congrats! Submission submitted.`);
        }
    }

    return (
        <>
            <header className="jumbotron mar-bot-spacing pb-md-4 pb-sm-3 pb-2 mt-md-4 pt-3 rounded-0 header-spacing">
                <Navigation />
                <div className="container bg-transparent mar-under-nav text-center bg-custom-2 mb-0 pt-sm-3 pt-2 pb-1 rounded-0">
                    <h1 className="pb-sm-2 pb-0 mb-sm-1 mb-0 pt-sm-0 pt-0">Janina's <br className="line-height display-inline-block show-hide"/>Web Designs<br /></h1>
                    <img src={dividerLineFull} alt="" className="line_full mb-3 header-line img-fluid" height={3} width={420} />
                    <img src={dividerLineMobile} alt="" className="line_mobile mb-1 header-line img-fluid" height={6} width={420} />
                    <h2 className="pt-md-2 pt-1 font-col-custom-1 animate__animated animate__zoomIn">Contact</h2>
                </div>
            </header>
            <motion.main 
                className="container pad-x-spacing"
                initial="initial" 
                animate="in" 
                exit="out" 
                variants={pageVariants} 
                transition={pageTransition}
                style={{ overflowX: "hidden" }}
            >
                
                {/* Section: Video */}
                <section className="mar-bot-spacing bor-rad-1">
                    {/* Video by mephala1980 from Pixabay, https://pixabay.com/videos/woman-phone-smartphone-technology-38084/, "Woman - 38084.mp4", modified */}
                    <video
                        className="img-fluid video_full"
                        playsInline
                        loop
                        muted
                        autoPlay
                        alt="All the devices"
                        src={videoCellFull}
                        controls={false}
                        preload='auto'
                        type='video/mp4'
                    />
                    <video
                        className="img-fluid video_mobile"
                        playsInline
                        loop
                        muted
                        autoPlay
                        alt="All the devices"
                        src={videoCellMobile}
                        controls={false}
                        preload='auto'
                        type='video/mp4'
                    />
                </section>

                {/* Section: Contact Form */}
                <section className="container px-3 py-1 bor-rad-1 mb-3 bg-custom-3 bg-mobile">
                    <Card className="text-white bg-custom-9 my-3 py-4 text-center">
                        <CardBody>
                            <CardText className="text-white m-0">Use the form below to reach me. I'll get back to you within 24 hours.</CardText>
                        </CardBody>
                    </Card>
                    <Form className="mt-4 pt-md-3 mt-0 mb-3 text-white" onSubmit={formSubmit}>
                        <FormGroup row>
                            <Label for="nameEntry" lg={2} md={3}>Full Name</Label>
                            <Col lg={10} md={9}>
                                <Input type="name" name="name" id="nameEntry" placeholder="Enter name" required value={name} onChange={e => setName(e.target.value)}/>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="emailEntry" lg={2} md={3}>Email</Label>
                            <Col lg={10} md={9}>
                                <Input type="email" name="email" id="emailEntry" placeholder="Enter email address"  required value={email} onChange={e => setEmail(e.target.value) }/>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="phoneEntry" lg={2} md={3}>Phone Number</Label>
                            <Col lg={10} md={9}>
                                <Input type="phone" name="phone" id="phoneEntry" placeholder="Enter phone number (optional)" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)}/>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="messageEntry" lg={2} md={3}>Message</Label>
                            <Col lg={10} md={9}>
                                <Input type="textarea" rows="4" name="text" id="messageEntry" placeholder="Enter message" required value={content} onChange={e => setContent(e.target.value)}/>
                            </Col>
                        </FormGroup>
                        <FormGroup check row>
                            <Col lg={{ size: 10, offset: 2 }} md={{ size: 9, offset: 3 }} className="pl-0">
                                <Button className="mt-2" color="primary">Submit</Button>
                            </Col>
                        </FormGroup>
                    </Form>
                </section> 

                {/* Section: Google Map */}
                <section className="contact-map mar-top-spacing pb-2 mar-bot-spacing bor-rad-1">
                    <div className="offset-lg-0 col-lg-12 px-lg-3 px-3 pb-1">
                        <h3 className="h4 text-center pb-3 pt-4"><span className="hide"></span>Toronto, Canada</h3>
                        <div className="iframe-container">
                            <iframe className="pb-2 px-1 fade-in-animation" title="Google Map of Toronto" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2885.939163340032!2d-79.38895208475334!3d43.67023505934744!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b34ae6f887699%3A0x6f439d160f6b85fb!2sYonge%20St%20%26%20Bloor%20St%20W%2C%20Toronto%2C%20ON%20M4Y%202B6!5e0!3m2!1sen!2sca!4v1603298523866!5m2!1sen!2sca" width={800} height={650} style={{border: 0}} allowFullScreen aria-hidden="false" tabIndex={0} />
                        </div>
                    </div>
                </section>
                
            </motion.main>
        </>
    )
}

export default Contact;