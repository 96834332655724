import React from 'react';
import { Row, Button } from 'reactstrap';
import { useHistory, Link } from 'react-router-dom';
import infoFull from './../../img/info-full.jpg';
import infoMobile from './../../img/info-mobile.jpg';
import Navigation from '../shared/Navigation';
import { motion } from 'framer-motion';
import ReadContactFormListings from '../shared/ReadContactForm';
import ReadPortfolio from '../shared/ReadPortfolio';
import ReadExperience from '../shared/ReadResumeExperience';
import ReadEducation from '../shared/ReadResumeEducation';
import { Tab, Col, Nav } from 'react-bootstrap';
import dividerLineFull from './../../img/divider-full.jpg';
import dividerLineMobile from './../../img/divider-mobile.jpg';

const Listings = () => {

    let history = useHistory();
    const logout = event => {
        event.preventDefault()
        sessionStorage.removeItem('token')
        history.push("/login")
    }

    const pageVariants = {
        initial: {
            opacity: 0,
            x: "-100vw"
        },
        in: {
            opacity: 1,
            x: 0
        },
        out: {
            opacity: 0,
            x: "100vw"
        }
    };

    const pageTransition = {
        duration: 1, 
        type: "spring",
        stiffness: 30,
        ease: "easeIn"
    };

    return (
        <>
            <header className="jumbotron mar-bot-spacing pb-md-4 pb-sm-3 pb-2 mt-md-4 pt-3 rounded-0 header-spacing">
                <Navigation />
                <div className="container bg-transparent mar-under-nav text-center bg-custom-2 mb-0 pt-sm-3 pt-2 pb-1 rounded-0">
                <h1 className="pb-sm-2 pb-0 mb-sm-1 mb-0 pt-sm-0 pt-0">Janina's <br className="line-height display-inline-block show-hide"/>Web Designs<br /></h1>
                    <img src={dividerLineFull} alt="" className="line_full mb-3 header-line img-fluid" height={3} width={420} />
                    <img src={dividerLineMobile} alt="" className="line_mobile mb-1 header-line img-fluid" height={6} width={420} />
                    <h2 className="pt-md-2 pt-1 font-col-custom-1 animate__animated animate__zoomIn">Listings</h2>
                </div>
            </header>
            <motion.main 
                className="container text-white pad-x-spacing mar-bot-spacing"
                initial="initial" 
                animate="in" 
                exit="out" 
                variants={pageVariants} 
                transition={pageTransition}
                style={{ overflowX: "hidden" }}
            >
                
                {/* Section: Image */}
                <section className="mar-bot-spacing bor-rad-1 bg-custom-2">
                    {/* Image by Peggy und Marco Lachmann-Anke from Pixabay, https://pixabay.com/illustrations/information-info-message-embassy-1015297/, information-1015297_1920.jpg */}
                    <img className="d-block w-100 bor-rad-1 img-fluid image_full" src={infoFull} width={1080} height={450} alt="Cartoon Character Leaning against Info Sign" title="Info Sign" />
                    <img className="d-block w-100 bor-rad-1 img-fluid image_mobile" src={infoMobile} width={1080} height={450} alt="Cartoon Character Leaning against Info Sign" title="Info Sign" />
                </section>

                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row className="mar-custom-1">
                        <Col sm={12} className="pl-3 pr-0">
                            <Nav variant="pills" className="flex-row">
                                <Nav.Item>
                                    <Nav.Link className="button-custom mr-1" eventKey="first">Contact</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className="button-custom mr-1" eventKey="second">Resume</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className="button-custom" eventKey="third">Portfolio</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col lg={12} className="p-0">
                        <Tab.Content>
                            <Tab.Pane className="bg-custom-1 bor-rad-bot-missing bor-custom-3 contact-pad" eventKey="first">
                                <ReadContactFormListings />
                            </Tab.Pane>
                            <Tab.Pane className="bg-custom-1 bor-rad-bot-missing bor-custom-3 resume-pad" eventKey="second">
                                <ReadExperience />
                                <ReadEducation />
                            </Tab.Pane>
                            <Tab.Pane className="bg-custom-1 bor-rad-bot-missing bor-custom-3 portfolio-pad" eventKey="third">
                                <ReadPortfolio />
                            </Tab.Pane>
                        </Tab.Content>
                        </Col>
                    </Row>
                    
                </Tab.Container>
                <div className="row m-0">
                    <div className="col-12 p-2 bor-custom-4 w-100"></div>
                </div>
                <div className="row d-flex justify-content-center bor-custom-3 pad-x-spacing m-0 bor-rad-top-missing bg-custom-6 py-3">
                    <Button className="ml-2" onClick={logout} color="primary">Logout</Button>
                    <Link to="/login/registration"><Button className="ml-2" color="primary">Create New Admin</Button></Link>
                </div>

            </motion.main>
        </>
    )
}

export default Listings;