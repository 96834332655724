import React, { useState } from 'react';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, Container } from 'reactstrap';
import { NavLink as RouteLink } from 'react-router-dom';
// import myLogo from './../../img/logo.png';
import videoJ from './../../video/Alphabet-105006-silver.mp4';

const Navigation = () => {
    const [isOpen, setIsOpen] = useState(false)
    const toggle = () => setIsOpen(!isOpen)

    return (
        <Navbar className="bg-custom-1 navbar-dark" expand="md" fixed="top" style={{ zIndex: 1000, postion: 'fixed !important'}}>
            <Container>
                <NavbarBrand className="brand text-white" href="/">
                    {/* <img className="logo" src={myLogo} width={29} height={29} alt="Logo" /> */}
                    <div class="custom-size">
                        {/* Video by DIGITAL MUSE from Pixabay https://pixabay.com/videos/alphabet-letters-text-105006/ alphabet-105006.mp4, modified */}
                        <video
                            className="img-fluid d-inline-block align-middle"
                            playsInline
                            loop
                            muted
                            autoPlay
                            alt='J Logo'
                            src={videoJ}
                            controls={false}
                            preload='auto'
                            type='video/mp4'
                            fluid={false}
                        />
                    </div>
                    <span className="name custom-font-5">Janina's Web Designs</span>
                </NavbarBrand>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="ml-auto" navbar>
                        <NavItem>
                            <NavLink tag={RouteLink} to="/" exact className="normal" activeClassName="active-link">Home</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={RouteLink} to="/resume" className="normal" activeClassName="active-link">Resume</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={RouteLink} to="/portfolio" className="normal" activeClassName="active-link">Portfolio</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={RouteLink} to="/contact" className="normal" activeClassName="active-link">Contact</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={RouteLink} to="/login" className="normal" activeClassName="active-link">Login</NavLink>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Container>
        </Navbar>
    )
}

export default Navigation;