import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { Route } from 'react-router-dom';
import ModalCreateExperience from '../individual/ModalCreateExperience';
import { withRouter } from "react-router";
import { useHistory } from 'react-router-dom';

const ReadExperience = (props) => {

    const [one, oneShow] = useState(false)
    const handleUpdateCloseModal = () => oneShow(false);
    const handleUpdateShowModal = (event, updateExperienceItem) => {
        event.preventDefault();
        oneShow(true);
        setUpdateExperienceItem(updateExperienceItem);
    };

    const [experiences, setExperiences] = useState([]);
    let id = props.match.params.id;
    const history = useHistory();
    const [updateExperienceItem, setUpdateExperienceItem] = useState({  years: "", title: "", company: "", description: "" });
    
    useEffect(() => {
        async function fetchData() {
        const res = await fetch(`${process.env.REACT_APP_BACKENDBASEURL}/api/experience`);
        res
            .json()
            .then((res) => setExperiences(res))
        }
        fetchData();
    }, [id]);   
    
    const handleSubmitDelete = (event, id) => {
        event.preventDefault();
        fetch(`${process.env.REACT_APP_BACKENDBASEURL}/api/experience/${id}`, {  
            method: "delete",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        }).then((response) => response.json());
        history.go(0)      
    };

    const handleUpdateChange = (event) => {
        event.persist();
        setUpdateExperienceItem((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
    };

    const handleUpdateSubmit = (event, id) => {
        event.preventDefault();
        fetch(`${process.env.REACT_APP_BACKENDBASEURL}/api/experience/${id}`, {
            method: "put",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(updateExperienceItem),
            }).then((response) => response.json());
            history.go(0);
        };

    return(
        <section className="work">
            <div className="d-flex justify-content-between">
                <h3 className="pb-md-4 pb-md-3 pb-2 pt-md-0 pt-1 d-inline-block">Experience</h3>
                <Route exact path="/login/listings" render={() => 
                    <div className="d-inline-block"><ModalCreateExperience /></div>
                }/>
            </div>
                <Route exact path="/login/listings" render={() => 
                    <hr className="custom-hr mt-0 pt-0"/>
                }/> 

            {experiences.map((experience) => (
                <div className="pb-md-3 pb-2" key={experience.id}>
                    <div className="row">
                        <div className="offset-md-0 col-md-2 col-12 pr-0">
                            <p className="pb-md-2 pb-0 custom-font">{experience.years}</p>
                        </div>
                        {/* <div className="offset-sm-0 col-sm-10 offset-1 col-11">  */}
                        <div className="offset-md-0 col-md-10 col-12">
                            {/* <h4 className="h5">{experience.title}, {experience.company}</h4> */}
                            <h4 className="h5">{experience.title}</h4>
                            <h4 className="h5">{experience.company}</h4>
                            <p className="pl-md-0 pl-3">{experience.description}</p>
                        </div>
                        <Route exact path="/login/listings" render={() => 
                            <div className="col-12 d-flex justify-content-end">
                                <Button
                                    className="btn-sm ml-2 text-uppercase" 
                                    color="primary"
                                    onClick={(e) => {
                                        handleUpdateShowModal(e, experience);
                                    }}
                                >
                                    Update
                                </Button>
                                <Button 
                                    className="btn-sm ml-2 text-uppercase" 
                                    color="primary"
                                    onClick={(e) => {
                                        handleSubmitDelete(e, experience.id);
                                        }}
                                >Delete</Button>
                            </div> 
                        }/>                    
                        <Route exact path="/login/listings" render={() => 
                            <hr className="custom-hr mx-3 mb-0"/>
                        }/>
                    </div>
                </div>
            ))}   

            <Modal
                show={one}
                onHide={handleUpdateCloseModal}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="modal-title w-100 text-center">Edit Experience Piece</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={(e) => handleUpdateSubmit(e, updateExperienceItem.id)}>
                        <Form.Group controlId="formBasicYears">
                            <Form.Label>Years</Form.Label>
                            <Form.Control
                                type="text"
                                name="years"
                                value={updateExperienceItem.years}
                                onChange={handleUpdateChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formBasicTitle">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                name="title"
                                value={updateExperienceItem.title}
                                onChange={handleUpdateChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formBasicCompany">
                            <Form.Label>Company</Form.Label>
                            <Form.Control
                                type="text"
                                name="company"
                                value={updateExperienceItem.company}
                                onChange={handleUpdateChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formBasicDescription">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                type="text"
                                name="description"
                                value={updateExperienceItem.description}
                                onChange={handleUpdateChange}
                            />
                        </Form.Group>
                        <Button className="in-modal-btn mt-4" variant="primary" type="submit" block>
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleUpdateCloseModal}>
                        Close Modal
                    </Button>
                </Modal.Footer>
            </Modal>

        </section>
    );
};

export default withRouter(ReadExperience);
