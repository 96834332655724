import React from 'react';
import Navigation from './../shared/Navigation';
import { motion } from 'framer-motion';
import ReadPorfolio from '../shared/ReadPortfolio';
import dividerLineFull from './../../img/divider-full.jpg';
import dividerLineMobile from './../../img/divider-mobile.jpg';
import videoGlobeFull from './../../video/Globe-123-full.mp4';
import videoGlobeMobile from './../../video/Globe-123-mobile.mp4';

const Portfolio = () => {

    const pageVariants = {
        initial: {
            opacity: 0,
            x: "-100vw"
        },
        in: {
            opacity: 1,
            x: 0
        },
        out: {
            opacity: 0,
            x: "100vw"
        }
    };

    const pageTransition = {
        duration: 1, 
        type: "spring",
        stiffness: 30,
        ease: "easeIn"
    };

    return (
        <>
            <header className="jumbotron mar-bot-spacing pb-md-4 pb-sm-3 pb-2 mt-md-4 pt-3 rounded-0 header-spacing">
                <Navigation />
                <div className="container bg-transparent mar-under-nav text-center bg-custom-2 mb-0 pt-sm-3 pt-2 pb-1 rounded-0">
                <h1 className="pb-sm-2 pb-0 mb-sm-1 mb-0 pt-sm-0 pt-0">Janina's <br className="line-height display-inline-block show-hide"/>Web Designs<br /></h1>
                    <img src={dividerLineFull} alt="" className="line_full mb-3 header-line img-fluid" height={3} width={420} />
                    <img src={dividerLineMobile} alt="" className="line_mobile mb-1 header-line img-fluid" height={6} width={420} />
                    <h2 className="pt-md-2 pt-1 font-col-custom-1 animate__animated animate__zoomIn">Portfolio</h2>
                </div>
            </header>
            <motion.main 
                className="container text-white pad-x-spacing"
                initial="initial" 
                animate="in" 
                exit="out" 
                variants={pageVariants} 
                transition={pageTransition}
                style={{ overflowX: "hidden" }}
            >

                {/* Section: Video */}
                <section className="mar-bot-spacing bor-rad-1">
                    <video
                        className="img-fluid video_full"
                        playsInline
                        loop
                        muted
                        autoPlay
                        alt="All the devices"
                        src={videoGlobeFull}
                        controls={false}
                        preload='auto'
                        type='video/mp4'
                    />
                    <video
                        className="img-fluid video_mobile"
                        playsInline
                        loop
                        muted
                        autoPlay
                        alt="All the devices"
                        src={videoGlobeMobile}
                        controls={false}
                        preload='auto'
                        type='video/mp4'
                    />
                </section>

                {/* Section: Portfolio */}
                <section>
                    
                    <ReadPorfolio/>

                </section> 
                
            </motion.main>
        </>
    )
}

export default Portfolio;